import { isProd } from '@/constants'
import Everpay from '@/libs/everpay-js'

const everpay = new Everpay({
  debug: !isProd
})

export const getEverpay = (): Everpay => {
  return everpay
}
