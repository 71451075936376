import { Web3Provider } from '@ethersproject/providers'
import { ChainType } from '../everpay-js'
import isUndefined from 'lodash/isUndefined'
import { isProd } from '@/constants'
import { toBN } from '../everpay-js/utils/util'
const connectAfterAddAsync = async (connectProvider: any, chainId: string, params: any): Promise<void> => {
  try {
    await connectProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }]
    })
  } catch (switchError: any) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      await connectProvider.request({
        method: 'wallet_addEthereumChain',
        params: [params]
      })
    } else {
      // handle other "switch" errors
      throw switchError
    }
  }
}

export const formatConnectProvider = (connectProvider: any, connectAppName: string): any => {
  const appName = connectAppName.replace(/\s*/g, '').toLocaleLowerCase()
  let provider = null
  if (connectProvider !== undefined && Reflect.has(connectProvider, 'providerMap')) {
    connectProvider.providerMap.forEach((item: any, key: string) => {
      if (appName === key.replace(/\s*/g, '').toLocaleLowerCase()) {
        provider = item
      }
    })
  }
  if (provider !== null) {
    return provider
  }
  return connectProvider
}
const getSelectedAddress = (connectProvider: any): string => {
  const selectedAddress: string | null = connectProvider.selectedAddress
  if (selectedAddress !== '' && selectedAddress !== null && selectedAddress !== undefined) {
    return connectProvider.selectedAddress
  } else {
    return ''
  }
}

export interface GetAccountAsyncParams {
  connectAppName: 'Metamask'
  chainType: ChainType
}
export const getAccountByWindowEthereumAsync = async (params: GetAccountAsyncParams): Promise<string> => {
  const { connectAppName, chainType } = params
  let connectProvider = window.ethereum
  connectProvider = formatConnectProvider(connectProvider, connectAppName)
  const isInstalled = !isUndefined(connectProvider)
  const isConnected = isInstalled && getSelectedAddress(connectProvider) !== ''

  if (!isInstalled) {
    setTimeout(() => {
      window.open('https://metamask.io/')
    }, 1000)
    throw new Error('pls_use_ethereum_wallet')
  }

  // metamask 一开始就有 window.ethereum 可以获取 网络 network，所以要做前置检查
  const provider = new Web3Provider(connectProvider)
  const network = await provider.getNetwork()
  try {
    checkNetwork(network.chainId, chainType)
  } catch (e) {
    if (connectAppName === 'Metamask') {
      if (chainType === ChainType.ethereum && !isProd) {
        const chainId = '0x' + toBN(5).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {
          chainId: chainId,
          chainName: 'Görli',
          nativeCurrency: {
            name: 'ETH',
            symbol: 'ETH',
            decimals: 18
          },
          rpcUrls: [
            'https://rpc.ankr.com/eth_goerli',
            'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
            'https://eth-goerli.public.blastapi.io'
          ],
          blockExplorerUrls: ['https://goerli.etherscan.io/']
        })
      } else if (chainType === ChainType.ethereum && isProd) {
        const chainId = '0x' + toBN(1).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {})
      } else if (chainType === ChainType.moon && !isProd) {
        const chainId = '0x' + toBN(1287).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {
          chainId: chainId,
          chainName: 'Moonbase Alpha',
          nativeCurrency: {
            name: 'DEV',
            symbol: 'DEV',
            decimals: 18
          },
          rpcUrls: ['https://rpc.api.moonbase.moonbeam.network'],
          blockExplorerUrls: ['https://moonbase.moonscan.io/']
        })
      } else if (chainType === ChainType.moon && isProd) {
        const chainId = '0x' + toBN(1284).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {
          chainId: chainId,
          chainName: 'Moonbeam',
          nativeCurrency: {
            name: 'GLMR',
            symbol: 'GLMR',
            decimals: 18
          },
          rpcUrls: ['https://rpc.api.moonbeam.network'],
          blockExplorerUrls: ['https://moonscan.io/']
        })
      } else if (chainType === ChainType.conflux && !isProd) {
        const chainId = '0x' + toBN(71).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {
          chainId: chainId,
          chainName: 'Conflux eSpace (Testnet)',
          nativeCurrency: {
            name: 'CFX',
            symbol: 'CFX',
            decimals: 18
          },
          rpcUrls: ['https://evmtestnet.confluxrpc.com'],
          blockExplorerUrls: ['https://evmtestnet.confluxscan.net/']
        })
      } else if (chainType === ChainType.conflux && isProd) {
        const chainId = '0x' + toBN(1030).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {
          chainId: chainId,
          chainName: 'Conflux eSpace',
          nativeCurrency: {
            name: 'CFX',
            symbol: 'CFX',
            decimals: 18
          },
          rpcUrls: ['https://evm.confluxrpc.com'],
          blockExplorerUrls: ['https://evm.confluxscan.net/']
        })
      } else if (chainType === ChainType.bsc && !isProd) {
        const chainId = '0x' + toBN(97).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {
          chainId: chainId,
          chainName: 'BSC Testnet',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
          },
          rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
          blockExplorerUrls: ['https://testnet.bscscan.com/']
        })
      } else if (chainType === ChainType.bsc && isProd) {
        const chainId = '0x' + toBN(56).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {
          chainId: chainId,
          chainName: 'BSC Mainnet',
          nativeCurrency: {
            name: 'BNB',
            symbol: 'BNB',
            decimals: 18
          },
          rpcUrls: ['https://bsc-dataseed1.ninicoin.io'],
          blockExplorerUrls: ['https://bscscan.com/']
        })
      } else if (chainType === ChainType.platon && !isProd) {
        const chainId = '0x' + toBN(2206132).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {
          chainId: chainId,
          chainName: 'PlatON Dev2Network',
          nativeCurrency: {
            name: 'LAT',
            symbol: 'LAT',
            decimals: 18
          },
          rpcUrls: ['https://devnet2openapi.platon.network/rpc'],
          blockExplorerUrls: ['https://devnet2scan.platon.network/']
        })
      } else if (chainType === ChainType.platon && isProd) {
        const chainId = '0x' + toBN(210425).toString(16)
        await connectAfterAddAsync(connectProvider, chainId, {
          chainId: chainId,
          chainName: 'PlatON MainNetwork',
          nativeCurrency: {
            name: 'LAT',
            symbol: 'LAT',
            decimals: 18
          },
          rpcUrls: ['https://openapi2.platon.network/rpc'],
          blockExplorerUrls: ['https://scan.platon.network/']
        })
      } else {
        throw e
      }
    } else {
      throw e
    }
  }

  if (!isConnected) {
    // 用户锁定 metamask 或 取消所有地址连接
    await connectProvider.request({ method: 'eth_requestAccounts' })
  }
  const accounts = await provider.listAccounts()
  const account = accounts[0] ?? ''
  return account
}

export const checkNetwork = (networkId: number, chainType: ChainType): void => {
  const isMainnet = networkId === 1
  const isGoerli = networkId === 5
  const isMoonbeam = networkId === 1284
  const isMoonbase = networkId === 1287
  const isConfluxTestnet = networkId === 71
  const isConfluxMainnet = networkId === 1030
  const isBSCMainnet = networkId === 56
  const isBSCTestnet = networkId === 97
  const isPlatonMainnet = networkId === 210425
  const isPlatonTestnet = networkId === 2206132

  if (chainType === ChainType.ethereum) {
    if (isProd && !isMainnet) {
      throw new Error('pls_change_to_mainnet')
    }

    if (!isProd && !isGoerli) {
      throw new Error('pls_change_to_goerli')
    }
  }

  if (chainType === ChainType.moon) {
    if (!isProd && !isMoonbase) {
      throw new Error('pls_change_to_moonbase')
    }
    if (isProd && !isMoonbeam) {
      throw new Error('pls_change_to_moonbeam')
    }
  }

  if (chainType === ChainType.conflux) {
    if (!isProd && !isConfluxTestnet) {
      throw new Error('pls_change_to_conflux_testnet')
    }
    if (isProd && !isConfluxMainnet) {
      throw new Error('pls_change_to_conflux_mainnet')
    }
  }

  if (chainType === ChainType.bsc) {
    if (!isProd && !isBSCTestnet) {
      throw new Error('pls_change_to_bsc_testnet')
    }
    if (isProd && !isBSCMainnet) {
      throw new Error('pls_change_to_bsc_mainnet')
    }
  }

  if (chainType === ChainType.platon) {
    if (!isProd && !isPlatonTestnet) {
      throw new Error('pls_change_to_platon_testnet')
    }
    if (isProd && !isPlatonMainnet) {
      throw new Error('pls_change_to_platon_mainnet')
    }
  }
}
