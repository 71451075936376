const messages = {
  home: '主頁',
  wallet: '錢包',
  NFT_auction: 'NFT拍賣',
  lang: '繁體中文',
  products: '產品',
  develop: '開發',
  v3_farm: '流動性挖礦',
  documentation: '文檔',
  support: '用戶支持',
  help_center: '幫助中心',
  privacy_policy: '隱私條款',
  faqs: '常見問題',
  about: '關於',
  explorer: '瀏覽器',
  light_paper: '白皮書',
  apps: 'Apps',
  slogan: '實時代幣協議',
  swap: '兌換',
  transactions: '交易',
  tokens: '代幣',
  discover_dapps: '探索 DApp',
  deposit: '充值',
  locker_address: '鎖倉地址',
  total_lock_amount: '總鎖倉金額',
  packaged_tx_num: '已上鏈交易數',
  pending_tx_num: '待上鏈交易數',
  total_tx_num: '總交易筆數',
  total_users: '交易地址數',
  demographic: '分佈圖',
  total_users_tip: '在 everPay 上發生過交易的用戶總數',
  everid_tip: '已註冊 ever 帳戶數',
  demographic_type: '帳戶類型',
  latest_txs: '最新交易',
  latest_arids: '最新的 Rollup tx',
  miner: '打包者',
  tx_num: '筆交易',
  view_more: '查看更多',
  locked_tokens: '代幣',
  token: '代幣',
  price: '價格',
  balance_amount: '數量',
  locked_volume: '數量',
  locked_market_cap: '市值',
  holders: '持倉賬戶數',
  more: '更多',
  view_on_etherscan: 'Etherscan 查看',
  view_on_viewblock: 'Viewblock 查看',
  view_on_moonscan: 'Moonscan 查看',
  view_on_conflux: 'Conflux 查看',
  view_on_bnb: 'Bscscan 查看',
  view_on_platon: 'Platon 查看',
  view_on_mapo: 'Maposcan 查看',
  view_on_aostest: 'ao Explorer 查看',
  view_on_psntest: 'Everscan 查看',
  contained_everapy_txs: '包含的 everPay 交易',
  date_time: '時間',
  contain_tx_nums: '包含筆數',
  everHash: 'everHash',
  type: '類型',
  status: '狀態',
  rollup_tx: 'Rollup tx',
  arId: 'ARID',
  from: '發送方',
  to: '接收方',
  amount: '金額',
  tx_fee: '手續費',
  internal_txs: '內部交易',
  internal_txs_failed: '內部交易（失敗）',
  target_chain_tx_hash: '目標鏈交易哈希',
  version: '版本',
  data: '附加數據',
  account_label: '賬戶：',
  tokens_label: '代幣：',
  copy_success: '複製成功',
  input_format: '未查詢到數據',
  if_you_help: '若需要幫助，',
  please_contact_us: '請聯絡我們',
  search_placeholder: '請輸入 ever 哈希、Rollup tx、地址交易哈希、ANS',
  internal_status: '內部狀態',
  network_error: '小 ever 正在努力加載，請稍後重試',
  viewable_number_chains: '可在{number}條鏈中查看',
  source_chain: '來源鏈',
  no_data: '暫無數據',
  covering_rollup_tx_everpay: '涵蓋 everPay 交易的 Rollup tx',
  first: '最前',
  pagination: '第{current}頁',
  manual_submission: '手動提交',
  connecting_wallet: '連接錢包',
  switch_the_network: '切換網路',
  connecting: '連接中',
  submit: '手動提交',
  submitting: '提交中',
  successfully_submitted: '提交成功',
  failed_submitted: '提交失敗',
  successful_transaction: '交易成功',
  failed_transaction: '交易失敗',
  manual_submission_tip: '在目標網路上，手動提交該交易',
  pls_use_ethereum_wallet: '請先安裝 MetaMask 錢包打開',
  connect_success: '連接成功',
  disconnect_success: '取消連接成功',
  success: '成功',
  addOwner: '新所有者',
  removeOwner: '所有者'
}
export default messages
