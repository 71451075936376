<template>
  <div
    v-for="(locker, index) in lockerArr"
    :key="index"
    class="flex flex-row relative py-8 sm:py-12  md:py-14 sm:px-10 md:px-12 lg:px-20"
    :style="`width: ${isScreenLayout ? lockerWidth : '100%'};`">
    <LockoutInfo
      v-for="(item, lockerIndex) in locker"
      :key="lockerIndex"
      :width="isScreenLayout ? '100%' : lockerWidth "
      :lockout="item">
      <div v-if="locker.length > 1 && lockerIndex === 0" class="border-r-2 border-dashed border-borderColor md:mx-12 lg:mx-20 sm:mx-5 sm:block hidden" />
    </LockoutInfo>
  </div>
</template>

<script lang="ts">
import { Locker } from '@/store/state'
import { defineComponent, PropType } from 'vue'
import LockoutInfo from './LockoutInfo.vue'
export default defineComponent({
  components: { LockoutInfo },
  props: {
    lockerArr: {
      type: Array as PropType<Locker[][]>,
      default: () => []
    },
    isScreenLayout: {
      type: Boolean,
      default: true
    },
    lockerWidth: {
      type: String,
      default: ''
    }
  },
  setup () {
    return {}
  }
})
</script>

<style lang="scss" scoped>

</style>
