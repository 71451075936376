const messages = {
  home: 'Accueil',
  wallet: 'Portefeuille',
  NFT_auction: 'Enchères NFT',
  lang: 'Français',
  products: 'Développer',
  develop: 'Développer',
  v3_farm: 'Liquidity Mining',
  documentation: 'Documentation',
  support: 'Support',
  help_center: "Centre d'aide",
  privacy_policy: 'Politique de confidentialité',
  faqs: 'FAQs',
  about: 'À propos',
  explorer: 'everScan',
  light_paper: 'Light Paper',
  apps: 'Apps',
  slogan: 'Protocole financier en temps réel pour la blockchain',
  swap: 'Swap',
  transactions: 'Transactions',
  discover_dapps: 'Découvrir les DApp',
  deposit: 'Dépôt',
  tokens: 'Tokens',
  locker_address: 'Adresse du coffre',
  total_lock_amount: 'Valeur totale bloquée',
  packaged_tx_num: 'Transaction réussie',
  pending_tx_num: 'Transaction en attente',
  total_tx_num: 'Nombre total de Txs',
  total_users: 'Utilisateurs',
  demographic: 'Distribution',
  total_users_tip: 'Nombre total d\'utilisateurs ayant effectué des transactions sur everPay',
  everid_tip: 'Nombre de comptes jamais enregistrés',
  demographic_type: 'Type d\'utilisateurs',
  latest_txs: 'Dernières transactions',
  latest_arids: 'Dernier rollup tx',
  miner: 'packager',
  tx_num: 'transactions',
  view_more: 'voir plus',
  locked_tokens: 'Tokens',
  token: 'Token',
  price: 'Prix',
  balance_amount: 'Montant',
  locked_volume: 'Offre totale',
  locked_market_cap: 'Capitalisation boursière',
  holders: 'Détenteurs',
  more: 'Plus de',
  view_on_etherscan: 'vue sur Etherscan',
  view_on_viewblock: 'vue sur Viewblock',
  view_on_moonscan: 'vue sur Moonscan',
  view_on_conflux: 'vue sur Conflux',
  view_on_platon: 'vue sur Platon',
  view_on_bnb: 'vue sur Bscscan',
  view_on_mapo: 'vue sur Maposcan',
  view_on_aostest: 'vue sur ao Explorer',
  view_on_psntest: 'vue sur Everscan',
  contained_everapy_txs: ' Transactions everPay incluses',
  date_time: 'Date',
  contain_tx_nums: 'Transactions',
  everHash: 'everHash',
  type: 'Type',
  status: 'Statut',
  rollup_tx: 'Rollup tx',
  arId: 'ARID',
  from: 'De',
  to: 'À',
  amount: 'Montant',
  tx_fee: 'Frais de Tx',
  internal_txs: 'Txs internes',
  internal_txs_failed: 'Txs internes (échoués)',
  target_chain_tx_hash: 'TxHash de la chaîne cible',
  version: 'Version',
  data: 'Données',
  account_label: 'Compte :',
  tokens_label: 'Token :',
  copy_success: 'Copie réussie',
  input_format: 'Oups ! Non trouvé',
  if_you_help: 'Si vous avez besoin d\'aide,',
  please_contact_us: 'veuillez nous contacter',
  search_placeholder: 'Recherche par everHash, Rollup tx, account, chainTxHash, ANS',
  internal_status: 'Statut Interne',
  network_error: 'Little n\'essaie jamais de charger, veuillez réessayer plus tard',
  viewable_number_chains: 'Visible en {number} chaînes',
  source_chain: 'Chaîne des sources',
  no_data: 'Aucune donnée trouvée',
  covering_rollup_tx_everpay: 'Tx de rollup couvrant les transactions everPay',
  first: 'D\'abord',
  pagination: 'Page {current}',
  manual_submission: 'Soumission manuelle',
  connecting_wallet: 'Connexion du portefeuille',
  switch_the_network: 'Changer de réseau',
  connecting: 'Connexion...',
  submit: 'Soumettre',
  submitting: 'Soumettre...',
  successfully_submitted: 'Soumis avec succès',
  failed_submitted: 'Échec de la soumission',
  successful_transaction: 'Transaction réussie',
  failed_transaction: 'Échec de la transaction ',
  manual_submission_tip: 'Soumettre manuellement la transaction sur le réseau cible',
  pls_use_ethereum_wallet: 'Veuillez installer le portefeuille MetaMask pour l\'ouvrir.',
  connect_success: 'Connexion réussie',
  disconnect_success: 'Déconnexion réussie',
  success: 'succès',
  addOwner: 'Nouveau propriétaire',
  removeOwner: 'Propriétaire'
}
export default messages
