<template>
  <div
    id="rotogramBox"
    class="bg-homegradient overflow-hidden shadow-lockerShadows relative items-center  flex flex-row rounded-xl sm:rounded-20px">
    <div
      class="absolute cursor-pointer h-full w-10  flex items-center justify-center z-10 left-0"
      @click="lockerTransform(currentLocker - 1)">
      <img
        src="@/images/rotation-arrow.png"
        class="w-6 h-6 transform rotate-180">
    </div>
    <div
      class="flex flex-row h-full relative"
      :class="isLockerTransition ? 'transition duration-700 ease-in-out' : ''"
      :style="`transform: translateX(-${lockertranslate}px);`"
      @mouseover="isBoxHoverStop = true"
      @mouseleave="isBoxHoverStop = false">
      <LockerCard
        v-for="(item,index) in lockersArrList"
        :key="index"
        :locker-arr="item"
        :is-screen-layout="isBigStyle"
        :locker-width="lockerWidth" />
    </div>
    <div class="absolute transform -translate-y-1/2 left-1/2 -translate-x-1/2  -bottom-1 sm:bottom-3 flex items-center">
      <div
        v-for="(item, index) in lockerLength"
        :key="index"
        class="w-4 h-4 cursor-pointer flex justify-center items-center"
        :class="index === 0 ? 'hidden' : 'block'"
        @click="lockerTransform(index)">
        <div
          class="rounded-full bg-white"
          :class="`${currentLocker === index ? 'opacity-80 w-1.5 h-1.5' : 'opacity-30 w-0.315 h-0.315 '}`" />
      </div>
    </div>
    <div
      class="absolute cursor-pointer right-0 h-full w-10 flex items-center justify-center  z-10 "
      @click="lockerTransform(currentLocker + 1)">
      <img
        src="@/images/rotation-arrow.png"
        class="w-6 h-6">
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, Ref, onActivated, onDeactivated, watch } from 'vue'
import { useStore } from '@/store'
import { useI18n } from 'vue-i18n'
import LockerCard from './LockerCard.vue'
import { Locker } from '@/store/state'
import isEmpty from 'lodash/isEmpty'
export default defineComponent({
  components: {
    LockerCard
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const lockers = computed(() => {
      return Object.values(store.state.lockers).filter((obj) => !isEmpty(obj))
    })
    const lockerList = computed(() => {
      return [...lockers.value]
    })
    const isBoxHoverStop = ref(false)
    const boxWidth = ref(0)
    const lockerWidth = ref('')
    const currentLocker = ref(1)
    const isLockerTransition = ref(true)
    const lockertranslate = ref(1200)
    const lockerLength = ref(0)
    const lockerTransform = (current: number) => {
      let index = current
      if (index < 0) {
        index = 1
      } else if (index >= lockerLength.value) {
        index = lockerLength.value
      }
      clearTimeout(intervalRotationTimer)
      isLockerTransition.value = true
      lockertranslate.value = index * boxWidth.value
      currentLocker.value = index
      intervalRotation()
    }
    const lockersArr = computed(() => {
      const result: Locker[][] = []
      let itemArr: Locker[] = []
      lockerList.value.forEach((wallet, index) => {
        if (itemArr.length < 2) {
          itemArr.push(wallet)
        }
        if (itemArr.length === 2 || lockerList.value.length - 1 === index) {
          result.push(itemArr)
          itemArr = []
        }
      })
      return result
    })
    const firstlockerArr:Ref<Locker[][]> = ref([])
    const lastlockerArr:Ref<Locker[][]> = ref([])
    const lockersArrList = computed(() => {
      return [lastlockerArr.value, lockersArr.value, firstlockerArr.value]
    })
    const isBigStyle = ref(true)
    const rotation = () => {
      const innerWidth = window.innerWidth
      clearTimeout(intervalRotationTimer)
      boxWidth.value = (document.getElementById('rotogramBox') as HTMLElement).offsetWidth
      if (innerWidth <= 640) {
        firstlockerArr.value = [[lockerList.value[0]]]
        lastlockerArr.value = [[lockerList.value[lockerList.value.length - 1]]]
        isBigStyle.value = false
        lockerLength.value = lockerList.value.length + 1
      } else {
        firstlockerArr.value = [lockersArr.value[0]]
        lastlockerArr.value = [lockersArr.value[lockersArr.value.length - 1]]
        isBigStyle.value = true
        lockerLength.value = lockersArr.value.length + 1
      }
      lockertranslate.value = currentLocker.value * boxWidth.value
      lockerWidth.value = boxWidth.value + 'px'
      intervalRotation()
    }
    const setTimeoutTranslate = () => {
      setTimeout(() => {
        isLockerTransition.value = false
        lockertranslate.value = currentLocker.value * boxWidth.value
      }, 650)
    }
    let intervalRotationTimer = null as any
    const intervalRotation = () => {
      if (!isBoxHoverStop.value) {
        if (currentLocker.value === lockerLength.value) {
          currentLocker.value = 1
          setTimeoutTranslate()
        } else if (currentLocker.value <= 0) {
          currentLocker.value = lockerLength.value - 1
          setTimeoutTranslate()
        }
      }

      intervalRotationTimer = setTimeout(() => {
        if (!isBoxHoverStop.value) {
          isLockerTransition.value = true
          currentLocker.value = currentLocker.value + 1
          lockertranslate.value = currentLocker.value * boxWidth.value
        }
        intervalRotation()
      }, 10000)
    }
    onActivated(() => {
      rotation()
      window.addEventListener('resize', rotation)
    })
    watch(() => lockers.value, () => {
      rotation()
    })
    onDeactivated(() => {
      window.removeEventListener('resize', rotation)
      clearTimeout(intervalRotationTimer)
    })
    return {
      t,
      lockerWidth,
      currentLocker,
      lockerTransform,
      lockertranslate,
      lockers,
      lockerList,
      isLockerTransition,
      isBigStyle,
      lockerLength,
      lockersArrList,
      isBoxHoverStop
    }
  }
})
</script>

<style>
</style>
