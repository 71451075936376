<template>
  <div
    class="relative"
    @mouseover="handleMouseOver"
    @mouseleave="handleMouseLeave">
    <slot v-if="$slots.info" name="info" />
    <div v-else class="whitespace-nowrap">
      {{ info }}
    </div>
    <transition name="fade">
      <div
        v-show="hoverd"
        class="absolute cursor-pointer"
        style="bottom: calc(100% + 12px);"
        :class="[fixedWidth ? 'w-64 sm:w-72' : '',leftView ? 'right-0' : 'left-0']"
      >
        <div
          class="relative p-2 text-white break-all text-xs"
          :class="fixedWidth ? '' : 'whitespace-nowrap'"
          style="background: rgba(27, 26, 44, 0.85);border-radius: 6px;">
          <slot />
        </div>
        <div class="divArrowRadius absolute" :class="leftView ? 'right-3.5' : 'left-3.5'" />
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {
    info: {
      type: String,
      default: ''
    },
    fixedWidth: {
      type: Boolean,
      default: false
    },
    leftView: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const route = useRoute()
    const hoverd = ref(false)
    let timer = null as any
    const handleMouseOver = () => {
      hoverd.value = true
      timer && clearTimeout(timer)
    }
    const handleMouseLeave = () => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        hoverd.value = false
      }, 100)
    }
    watch(() => route.name, () => {
      hoverd.value = false
    })
    return {
      hoverd,
      handleMouseOver,
      handleMouseLeave
    }
  }
})
</script>
<style scoped></style>
