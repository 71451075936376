const messages = {
  home: 'Home',
  wallet: 'Wallet',
  NFT_auction: 'NFT Auction',
  lang: 'English',
  products: 'Products',
  develop: 'Develop',
  v3_farm: 'Liquidity Mining',
  documentation: 'Documentation',
  support: 'Support',
  help_center: 'Help Center',
  privacy_policy: 'Privacy Policy',
  faqs: 'FAQs',
  about: 'About',
  explorer: 'Explorer',
  light_paper: 'Light Paper',
  apps: 'Apps',
  slogan: 'Real-time financial protocol for the blockchain',
  swap: 'Swap',
  transactions: 'Transactions',
  tokens: 'Tokens',
  discover_dapps: 'Discover DApp',
  deposit: 'Deposit',
  locker_address: 'Locker Address',
  total_lock_amount: 'Total Value Locked',
  packaged_tx_num: 'Successful transaction',
  pending_tx_num: 'Pending transaction',
  total_tx_num: 'Number of total Txs',
  total_users: 'Users',
  demographic: 'Distribution',
  total_users_tip: 'Total number of users who have traded on everPay',
  everid_tip: 'Number of ever accounts registered',
  demographic_type: 'Type',
  latest_txs: 'Latest Transactions',
  latest_arids: 'Latest Rollup tx',
  miner: 'packager',
  tx_num: 'transactions',
  view_more: 'View more',
  locked_tokens: 'Tokens',
  token: 'Token',
  price: 'Price',
  balance_amount: 'Amount',
  locked_volume: 'Total Supply',
  locked_market_cap: 'Market Cap',
  holders: 'Holders',
  more: 'More',
  view_on_etherscan: 'view on Etherscan',
  view_on_viewblock: 'view on Viewblock',
  view_on_moonscan: 'View on Moonscan',
  view_on_conflux: 'View on Conflux',
  view_on_bnb: 'View on Bscscan',
  view_on_platon: 'View on Platon',
  view_on_mapo: 'View on Maposcan',
  view_on_aostest: 'View on ao Explorer',
  view_on_psntest: 'View on Everscan',
  contained_everapy_txs: ' included everPay transactions',
  date_time: 'Date Time',
  contain_tx_nums: 'Transactions',
  everHash: 'everHash',
  type: 'Type',
  status: 'Status',
  rollup_tx: 'Rollup tx',
  arId: 'ARID',
  from: 'From',
  to: 'To',
  amount: 'Amount',
  tx_fee: 'Tx Fee',
  internal_txs: 'Internal Txs',
  internal_txs_failed: 'Internal Txs(Failed)',
  target_chain_tx_hash: 'Target Chain TxHash',
  version: 'Version',
  data: 'Data',
  account_label: 'Account:',
  tokens_label: 'Token:',
  copy_success: 'Copy Successful',
  input_format: 'Oops! Not found. ',
  if_you_help: 'If you need help,',
  please_contact_us: 'please contact us',
  search_placeholder: 'Search by everHash, Rollup tx, account, chainTxHash, ANS',
  internal_status: 'Internal Status',
  network_error: 'struggling to load data, please try again later.',
  viewable_number_chains: 'Viewable in {number} chains',
  source_chain: 'Source chain',
  no_data: 'No data found',
  covering_rollup_tx_everpay: 'Rollup tx covering everPay transactions',
  first: 'First',
  pagination: 'Page {current}',
  manual_submission: 'Manual submission',
  connecting_wallet: 'Connecting Wallet',
  switch_the_network: 'Switch the network',
  connecting: 'Connecting…',
  submit: 'Submit',
  submitting: 'Submitting…',
  successfully_submitted: 'Successfully submitted',
  failed_submitted: 'Failed submitted',
  successful_transaction: 'Successful transaction',
  failed_transaction: 'Failed transaction',
  manual_submission_tip: 'Manually submit the transaction on the target network',
  pls_use_ethereum_wallet: 'Please install MetaMask wallet to open',
  connect_success: 'Connect successful',
  disconnect_success: 'Disconnect successful',
  success: 'successful',
  addOwner: 'New owner',
  removeOwner: 'Owner'
}
export default messages
