const messages = {
  home: '主页',
  wallet: '钱包',
  NFT_auction: 'NFT拍卖',
  lang: '简体中文',
  products: '产品',
  develop: '开发',
  v3_farm: '流动性挖矿',
  documentation: '文档',
  support: '用户支持',
  help_center: '帮助中心',
  privacy_policy: '隐私条款',
  faqs: '常见问题',
  about: '关于',
  explorer: 'everScan',
  light_paper: '白皮书',
  apps: 'Apps',
  slogan: '实时代币协议',
  swap: '兑换',
  transactions: '交易',
  tokens: '代币',
  discover_dapps: '探索 DApp',
  deposit: '充值',
  locker_address: '锁仓地址',
  total_lock_amount: '总锁仓金额',
  packaged_tx_num: '已上链交易数',
  pending_tx_num: '待上链交易数',
  total_tx_num: '总交易笔数',
  total_users: '交易地址数',
  demographic: '分布图',
  total_users_tip: '在 everPay 上发生过交易的用户总数',
  everid_tip: '已注册的 EverID - Email 账户数',
  demographic_type: '账户类型',
  latest_txs: '最新交易',
  latest_arids: '最新的 Rollup tx',
  miner: '打包者',
  tx_num: '笔交易',
  view_more: '查看更多',
  locked_tokens: '代币',
  token: '代币',
  price: '价格',
  balance_amount: '数量',
  locked_volume: '数量',
  locked_market_cap: '市值',
  holders: '持仓账户数',
  more: '更多',
  view_on_etherscan: 'Etherscan 查看',
  view_on_viewblock: 'Viewblock 查看',
  view_on_moonscan: 'Moonscan 查看',
  view_on_conflux: 'Conflux 查看',
  view_on_bnb: 'Bscscan 查看',
  view_on_platon: 'Platon 查看',
  view_on_mapo: 'Maposcan 查看',
  view_on_aostest: 'ao Explorer 查看',
  view_on_psntest: 'Everscan 查看',
  contained_everapy_txs: '包含的 everPay 交易',
  date_time: '时间',
  contain_tx_nums: '包含笔数',
  everHash: 'everHash',
  type: '类型',
  status: '状态',
  rollup_tx: 'Rollup tx',
  arId: 'ARID',
  from: '发送方',
  to: '接收方',
  amount: '金额',
  tx_fee: '手续费',
  internal_txs: '内部交易',
  internal_txs_failed: '内部交易（失败）',
  target_chain_tx_hash: '目标链交易哈希',
  version: '版本',
  data: '附加数据',
  account_label: '账户：',
  tokens_label: '代币：',
  copy_success: '复制成功',
  input_format: '未查询到数据',
  if_you_help: '若需要帮助，',
  please_contact_us: '请联系我们吧',
  search_placeholder: '请输入 ever 哈希、Rollup tx、地址、交易哈希、ANS',
  internal_status: '内部状态',
  network_error: '小 ever 正在努力加载，请稍后重试',
  viewable_number_chains: '可在{number}条链中查看',
  source_chain: '来源链',
  no_data: '暂无数据',
  covering_rollup_tx_everpay: '涵盖 everPay 交易的 Rollup tx',
  first: '最前',
  pagination: '第{current}页',
  manual_submission: '手动提交',
  connecting_wallet: '连接钱包',
  switch_the_network: '切换网络',
  connecting: '连接中',
  submit: '手动提交',
  submitting: '提交中',
  successfully_submitted: '提交成功',
  failed_submitted: '提交失败',
  successful_transaction: '交易成功',
  failed_transaction: '交易失败',
  manual_submission_tip: '在目标网络上，手动提交该交易',
  pls_use_ethereum_wallet: '请先安装 MetaMask 钱包打开。',
  connect_success: '连接成功',
  disconnect_success: '取消连接成功',
  success: '成功',
  addOwner: '新所有者',
  removeOwner: '所有者'
}
export default messages
