
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {
    info: {
      type: String,
      default: ''
    },
    fixedWidth: {
      type: Boolean,
      default: false
    },
    leftView: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    const route = useRoute()
    const hoverd = ref(false)
    let timer = null as any
    const handleMouseOver = () => {
      hoverd.value = true
      timer && clearTimeout(timer)
    }
    const handleMouseLeave = () => {
      timer && clearTimeout(timer)
      timer = setTimeout(() => {
        hoverd.value = false
      }, 100)
    }
    watch(() => route.name, () => {
      hoverd.value = false
    })
    return {
      hoverd,
      handleMouseOver,
      handleMouseLeave
    }
  }
})
