<script lang="ts">
import { ref, computed, onMounted, watch, defineComponent } from 'vue'
import ScrollPicker from 'vue3-scroll-picker'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Search from './Search.vue'
import { isProd, everpayOfficialWebsiteHost, savedI18nStorageKey, isMobile, isEverpayApp, isEverpayFarm, isEverpayOfficialWebsite, everpayAppHost, everpayScanHost, isEverpayScan } from '@/constants'
import { checkParentsHas } from '@/libs/utils'

export default defineComponent({
  components: {
    ScrollPicker,
    Search
  },
  props: {
    scrollTop: {
      type: Number,
      default: 0
    }
  },
  emits: ['handleLocaleChange', 'handleBtnClick'],
  setup (props, context) {
    const isLangWrapperTarget = checkParentsHas('lang-wrapper')
    const isResourcesWrapperTarget = checkParentsHas('resources-wrapper')
    const { t, locale } = useI18n({ useScope: 'global' })
    const route = useRoute()
    const windowWidth = ref(document.documentElement.offsetWidth)
    const mobileNavModalOpened = ref(false)
    const languagesVisible = ref(false)
    const resourcesVisible = ref(false)
    const navbarTextColorClass = isEverpayFarm ? 'text-everPurple9' : 'text-everPurple0'
    const navbarTextColorHoverClass = isEverpayFarm ? 'hover:text-everPurple9' : 'hover:text-everPurple0'
    const navbarBtnBorderClass = isEverpayFarm ? 'border-everPurple9' : 'border-everPurple0'
    const navbarBtnBackClass = isEverpayFarm ? 'connect-btn-bg-farm' : 'connect-btn-bg-normal'
    const navItems = computed(() => {
      return [
        {
          title: 'home',
          routeNames: ['home'],
          openNew: false,
          to: `${everpayScanHost}/`
        },
        {
          title: 'transactions',
          routeNames: ['txs'],
          openNew: false,
          to: `${everpayScanHost}/txs`
        },
        {
          title: 'tokens',
          routeNames: ['tokens'],
          openNew: false,
          to: `${everpayScanHost}/tokens`
        },
        {
          title: 'apps',
          routeNames: [],
          to: ''
        }
      ]
    })

    const appItems = computed(() => {
      return [
        {
          title: 'discover_dapps',
          openNew: !isEverpayApp,
          to: `https://beta${isProd ? '' : '-dev'}.everpay.io/dapps`
        },
        {
          title: 'deposit',
          openNew: !isEverpayApp,
          to: `${everpayAppHost}/deposit`
        },
        {
          title: 'wallet',
          openNew: !isEverpayApp,
          to: everpayAppHost
        },
        {
          title: 'documentation',
          openNew: !isEverpayFarm,
          to: `https://docs.everpay.io/${
            (locale.value as any).includes('zh') ? '' : 'en/'
          }`
        }
      ]
    })
    const displaySearch = computed(() => route.name !== 'home')
    const mobileNavItems = computed(() => {
      return navItems.value.filter(item => item.title !== 'apps').concat(appItems.value as any)
    })

    const languages = [
      {
        value: 'en',
        label: 'English'
      },
      {
        value: 'zh',
        label: '简体中文'
      }
    ]
    if (isEverpayScan) {
      languages.push({
        value: 'fr',
        label: 'Français'
      },
      {
        value: 'zh-TW',
        label: '繁體中文'
      })
    }
    if (isEverpayOfficialWebsite) {
      languages.push({
        value: 'es',
        label: 'Español'
      },
      {
        value: 'fr',
        label: 'Français'
      },
      {
        value: 'zh-TW',
        label: '繁體中文'
      })
    }
    const scrollPickerOptions = [languages]
    const scrollPickerValues = ref([locale.value])

    watch(locale, () => {
      scrollPickerValues.value = [locale.value]
    })

    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = document.documentElement.offsetWidth
        if (windowWidth.value >= 640) {
          mobileNavModalOpened.value = false
          languagesVisible.value = false
        }
        resourcesVisible.value = false
      })

      window.addEventListener('scroll', () => {
        if (windowWidth.value >= 640) {
          languagesVisible.value = false
        }
        resourcesVisible.value = false
      })

      document.addEventListener('click', (e) => {
        if (!isLangWrapperTarget(e.target as any)) {
          languagesVisible.value = false
        }
        if (!isResourcesWrapperTarget(e.target as any)) {
          resourcesVisible.value = false
        }
      })
    })

    const changeLocale = (lang: string) => {
      window.localStorage.setItem(savedI18nStorageKey, lang)
      locale.value = lang
      context.emit('handleLocaleChange', lang)
      mobileNavModalOpened.value = false
      languagesVisible.value = false
    }

    const confirmLocale = () => {
      locale.value = scrollPickerValues.value[0]
      context.emit('handleLocaleChange', locale.value)
      languagesVisible.value = false
      mobileNavModalOpened.value = false
    }
    return {
      t,
      isProd,
      isEverpayApp,
      isMobile,
      everpayOfficialWebsiteHost,
      locale,
      route,
      navItems,
      languagesVisible,
      languages,
      changeLocale,
      confirmLocale,
      mobileNavModalOpened,
      scrollPickerValues,
      scrollPickerOptions,
      resourcesVisible,
      appItems,
      mobileNavItems,
      navbarBtnBorderClass,
      navbarTextColorClass,
      navbarTextColorHoverClass,
      navbarBtnBackClass,
      displaySearch
    }
  }
})

</script>

<template>
  <div
    class="sm:h-16 h-60px fixed px-5 z-20 top-0 left-0 w-full flex items-center justify-center delay-300 transition-shadow"
    :class="`${scrollTop > 0 ? 'frosted-glass' : 'sm:bg-scanNavBar bg-scanSmallNavBar'}`"
  >
    <div class="h-full flex flex-row items-center justify-between w-full" style="max-width:1440px">
      <router-link to="/" class="h-7 flex flex-row items-center md:mr-10">
        <img class="block h-7" :src="require('../images/everscan.svg')">
        <span class="ml-2 text-xs mt-1" style="rgba(0, 0, 0, 0.85);">{{ isProd ? 'Mainnet' : 'Testnet' }}</span>
      </router-link>
      <div class="flex flex-row items-center md:flex-1 justify-end lg:flex-none">
        <ul
          class="flex-row items-center justify-between hidden h-full relative mr-10"
          :class="locale.includes('zh') || locale === 'en' ? 'md:flex' : 'lg:flex'"
        >
          <li v-for="(item, index) in navItems" :key="index" class="md:mr-10 lg:mr-16 last:mr-0 whitespace-nowrap">
            <a
              v-if="item.openNew"
              :target="!isMobile && item.to ? '_blank' : ''"
              :href="item.to ? item.to : 'javascript:void(0);'"
              class="text-sm text-everBlack2 transition-colors"
              :class="navbarTextColorHoverClass"
            >
              {{ t(item.title) }}
            </a>
            <router-link
              v-else-if="item.to"
              :to="item.to"
              class="text-sm transition-colors"
              :class="`${navbarTextColorHoverClass} ${item.routeNames.includes(route.name) ? navbarTextColorClass : 'text-everBlack2'}`"
            >
              {{ t(item.title) }}
            </router-link>
            <div
              v-else
              class="text-sm text-everBlack2 transition-colors cursor-pointer flex flex-row items-center resources-wrapper"
              :class="navbarTextColorHoverClass"
              @click="resourcesVisible = !resourcesVisible"
            >
              <span class="mr-2">{{ t(item.title) }}</span>
              <img class="w-2.5 transform transition duration-300 mt-0.5" :class="resourcesVisible ? 'rotate-180' : 'rotate-0'" :src="require('../images/lang-arrow-down.svg')">
              <transition v-if="item.title === 'apps'" name="fade">
                <div
                  v-if="resourcesVisible"
                  class="absolute right-0 bg-white py-0.5 rounded-lg resources-dropdown"
                  style="top:30px;"
                >
                  <div v-for="(resource, resourceIndex) in appItems" :key="resourceIndex" class=" flex flex-row items-center justify-start text-everBlack2 transition-colors">
                    <a
                      v-if="resource.openNew"
                      :href="resource.to ? resource.to : 'javascript:void(0);'"
                      :target="resource.to ? '_blank' : ''"
                      class="w-full px-8 py-2 current-title"
                    >{{ t(resource.title) }}</a>
                    <router-link v-else :to="resource.to" class="w-full px-8 py-2 current-title">
                      {{ t(resource.title) }}
                    </router-link>
                  </div>
                </div>
              </transition>
            </div>
          </li>
        </ul>
        <Search v-if="displaySearch" class="xl:w-80  w-full lg:block hidden" />
        <div class="flex flex-row items-center justify-end h-full ml-10 lg:ml-8">
          <div
            class="lang-wrapper border py-1.5 rounded-md px-3 text-sm relative h-full text-black flex-row items-center justify-center cursor-pointer hidden"
            :class="`${locale.includes('zh') || locale === 'en' ? 'md:flex' : 'lg:flex'} ${navbarTextColorHoverClass}`"
            @click="languagesVisible = !languagesVisible"
          >
            <span class="mr-2 whitespace-nowrap">{{ t('lang') }}</span>
            <img class="w-2.5 transform transition duration-300" :class="languagesVisible ? 'rotate-180' : 'rotate-0'" :src="require('../images/lang-arrow-down.svg')">
            <transition name="fade">
              <div
                v-if="languagesVisible"
                class="absolute right-0 bg-white py-0.5 rounded-lg lang-dropdown"
                style="top:40px;"
              >
                <div
                  v-for="langItem in languages"
                  :key="langItem.value"
                  class="flex flex-row items-center current-title justify-start text-everBlack2 px-8 py-2 transition-colors"
                  @click.stop="changeLocale(langItem.value)">
                  <span class="whitespace-nowrap">{{ langItem.label }}</span>
                </div>
              </div>
            </transition>
          </div>
          <div
            class="ml-2.5 rounded w-8 h-8 flex flex-row items-center justify-center cursor-pointer bg-smallNavBar"
            :class="locale.includes('zh') || locale === 'en' ? 'md:hidden' : 'lg:hidden'"
            @click="mobileNavModalOpened = !mobileNavModalOpened"
          >
            <img
              class="w-4 h-4 block"
              :src="require(`../images/${mobileNavModalOpened ? 'close' : 'menu'}.svg`)"
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- only for mobile -->
  <transition name="fade">
    <div
      v-if="mobileNavModalOpened"
      class="bg-black bg-opacity-25 fixed top-16 left-0 z-10 h-screen w-full px-5"
      @click="mobileNavModalOpened = false"
    >
      <div class="relative top-4 bg-white rounded-lg">
        <ul class="flex flex-col items-center justify-start p-5">
          <li v-for="(item, index) in mobileNavItems" :key="index" class=" w-full">
            <a
              v-if="item.openNew"
              class="text-base block text-center py-4 text-everBlack2 transition-colors"
              :class="navbarTextColorHoverClass"
              :target="!isMobile && item.to ? '_blank' : ''"
              :href="item.to ? item.to : 'javascript:void(0);'"
            >
              {{ t(item.title) }}
            </a>
            <router-link
              v-else
              class="text-base block text-center py-4 transition-colors"
              :class="`
                ${navbarTextColorHoverClass}
                ${item.routeNames && item.routeNames.includes(route.name) ? navbarTextColorClass : 'text-everBlack2'}
              `"
              :to="item.to"
            >
              {{ t(item.title) }}
            </router-link>
          </li>
          <li class=" w-full">
            <div
              class="text-base flex text-center py-4 relative flex-row items-center justify-center cursor-pointer lang-wrapper"
              @click.stop="languagesVisible = !languagesVisible"
            >
              <span class="mr-2">{{ t('lang') }}</span>
              <img class="w-2.5" :src="require('../images/lang-arrow-down.svg')">
            </div>
          </li>
        </ul>
      </div>
      <transition name="fade">
        <div
          v-if="languagesVisible"
          class="fixed w-full left-0 lang-wrapper bottom-0"
        >
          <div class="h-9 px-5 flex flex-row items-center justify-end text-everPurple8" style="background:#f0f0f2;">
            <span class="cursor-pointer" @click.stop="confirmLocale">{{ t('done') }}</span>
          </div>
          <scroll-picker
            v-model="scrollPickerValues"
            inactive-class="inactive-scroll-picker-item"
            active-class="active-scroll-picker-item"
            :options="scrollPickerOptions"
          >
            <template #top-overlay>
              <div class="custom-top-overlay" />
            </template>
            <template #center-overlay>
              <div class="custom-center-overlay" />
            </template>
            <template #bottom-overlay>
              <div class="custom-bottom-overlay" />
            </template>
          </scroll-picker>
        </div>
      </transition>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.frosted-glass {
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(46px);
}
.resources-dropdown,
.lang-dropdown {
  box-shadow: 0px 0px 8px rgba(153, 142, 186, 0.2);
}
.connect-btn {
  border-radius: 8.59722px;
  background-size: 200% 100%;
  background-position: left center;
  transition: background-position 1s;
}
.connect-btn-bg-farm {
  background-image: linear-gradient(94.12deg, rgba(255, 70, 159, 0.7) 0%, rgba(227, 45, 255, 0.7) 107.59%);
}
.connect-btn-bg-normal {
  background-image: linear-gradient(98.82deg,#9b81e6 1.77%,#5356bd 50%,#9b81e6 95.44%);
}
.connect-btn:hover {
  background-position: right center;
}
</style>

<style>
.column-container,
.column-overlay {
  width: 100%;
  text-align: center;
}
.column-container {
  width: 100%;
  background: #d1d3d9;
}
.custom-top-overlay {
  height: 100%;
  background: rgba(209, 211, 217, 0.1);
}
.custom-bottom-overlay {
  height: 100%;
  background: rgba(209, 211, 217, 0.1);
}
.inactive-scroll-picker-item {
  font-size: 14px;
  color: #8a898d;
}
.active-scroll-picker-item {
  margin: 0 12px;
  border-radius: 6px;
  font-size: 16px;
  color: #333;
  background: #c5c7ce;
}
.current-title:hover {
  background: #F7F7FB;
  transition:  all .3s;
}
</style>
