<template>
  <div class="flex-1">
    <Navbar :scroll-top="scrollTop" />
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
  <Footer id="footer" class="flex-0" />
</template>
<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from '@/store'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default defineComponent({
  name: 'App',
  components: {
    Navbar,
    Footer
  },

  setup () {
    const store = useStore()
    const scrollTop = ref(0)

    onMounted(async () => {
      store.dispatch('updateLockerInfoAsync')
      store.dispatch('updateTokenListAsync')
      store.dispatch('updateManualBurnTokensAsync')
      store.dispatch('updateCurrencyPricesAsnyc')
      window.addEventListener('scroll', () => {
        scrollTop.value = document.documentElement.scrollTop || document.body.scrollTop
      })
    })
    return {
      scrollTop
    }
  }
})
</script>
