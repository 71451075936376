<template>
  <div class=" pb-4 bg-white rounded-xl shadow-scanShadows text-sm w-full mb-10">
    <div class="pb-5 pt-8 sm:px-11 px-4 border-b border-black-500 border-solid flex items-center">
      <div class="flex items-center justify-between w-full">
        <slot />
        <RouterLink :to="to" class="sm:block hidden">
          <div class="flex items-center">
            <span class="text-everPurple6 active:text-scanClick text-sm whitespace-nowrap">{{ t('view_more') }}</span>
            <img src="@/images/arrow-angle-right.svg" class="w-4 h-4 transform scale-150 ml-1 translate-y-px" alt="view More arrow">
          </div>
        </RouterLink>
      </div>
    </div>
    <div
      class="flex justify-center ease-linear duration-300 overflow-hidden"
      :class="isLoading ? 'h-6 my-3' : 'h-0'">
      <img v-if="isLoading" class="w-6 h-6  animate-spin-slow" :src="require('../../../images/loading.svg')">
    </div>
    <div class="sm:px-11 px-4">
      <div v-if="!isLoading && !list.length" class="mt-5 text-center text-everPurple6">
        {{ t('no_data') }}
      </div>
      <Card
        v-for="(item,index) in list"
        :key="index"
        :time-nonce="item.nonce || (item.timestamp * 1000)"
        :action="item.action"
        :owner="item.owner"
        :amount="item.amount"
        :rollup-tx-num="item.rollupTxNum"
        :token-symbol="item.tokenSymbol"
        :token="formatTokenTag(item, tokens)"
        :is-arid="item.height ? true : false "
        :class="index !== list.length-1 ? 'border-b border-dashed' : ''">
        <SimpleWrapper v-if="item.height">
          <router-link
            :to="`/arId/${item.arId}`">
            <MoreInfo :fixed-width="true" :info="formatInfo(item.arId)">
              {{ item.arId }}
            </MoreInfo>
          </router-link>
        </SimpleWrapper>
        <SimpleWrapper v-else>
          <router-link
            :to="`/tx/${item.everHash}`">
            <MoreInfo :fixed-width="true" :info="formatInfo(item.everHash)">
              {{ item.everHash }}
            </MoreInfo>
          </router-link>
        </SimpleWrapper>
      </Card>
    </div>
    <RouterLink :to="to" class="sm:hidden block ml-4 mt-4 w-max">
      <div class="flex items-center">
        <span class="text-everPurple6 active:text-scanClick text-xs">{{ t('view_more') }}</span>
        <img src="@/images/arrow-angle-right.svg" class="w-4 h-4 transform ml-1 scale-150 translate-y-px" alt="view More arrow">
      </div>
    </RouterLink>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import Card from './Card.vue'
import { useI18n } from 'vue-i18n'
import SimpleWrapper from './SimpleWrapper.vue'
import MoreInfo from '@/components/common/MoreInfo.vue'
import { useStore } from '@/store'
import { formatTokenTag } from '@/libs/utils'
import { RouterLink } from 'vue-router'
export default defineComponent({
  components: {
    Card,
    SimpleWrapper,
    MoreInfo,
    RouterLink
  },
  props: {
    list: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    to: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const { t } = useI18n()
    const store = useStore()
    const tokens = computed(() => store.state.tokenList)
    const formatInfo = (info: string) => {
      return info.slice(0, 16) + '...'
    }
    return { t, formatInfo, tokens, formatTokenTag }
  }
})
</script>

<style>

</style>
