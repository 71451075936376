<script lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, defineComponent } from 'vue'
import { isMobile, isEverpayApp, everpayAppHost, isEverpayScan, everpayScanHost, isEverpayFarm, everpayFarmHost } from '@/constants'

export default defineComponent({
  setup () {
    const socials = [
      {
        link: 'https://twitter.com/everVisionHQ',
        name: 'twitter'
      },
      {
        link: 'https://discord.gg/WM5MQZGVPF',
        name: 'discord'
      },
      {
        link: 'https://t.me/FinanceEver',
        name: 'telegram'
      },
      {
        link: /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ? 'https://medium.com/everfinance' : 'https://news.ever.vision',
        name: 'medium'
      },
      {
        link: 'https://github.com/everFinance',
        name: 'github'
      }
    ]

    const { t, locale } = useI18n()

    const getComputedFooterEntries = (t: any, locale: string) => {
      return [
        {
          title: t('products'),
          items: [
            {
              title: t('wallet'),
              to: isEverpayApp ? '/' : '',
              link: everpayAppHost
            },
            {
              title: t('swap'),
              to: isEverpayApp ? '/swap' : '',
              link: `${everpayAppHost}/swap`
            },
            {
              title: t('NFT_auction'),
              to: isEverpayApp ? '/nft-auction' : '',
              link: `${everpayAppHost}/nft-auction`
            },
            {
              title: t('v3_farm'),
              to: isEverpayFarm ? '/' : '',
              link: `${everpayFarmHost}`
            },
            {
              title: t('explorer'),
              to: isEverpayScan ? '/' : '',
              link: everpayScanHost
            }
          ]
        },
        {
          title: t('develop'),
          items: [
            {
              title: t('documentation'),
              link: `https://docs.everpay.io/${locale.includes('zh') ? '' : 'en'}`
            },
            {
              title: 'everpay-js',
              link: 'https://github.com/everFinance/everpay-js'
            }
          ]
        },
        {
          title: t('support'),
          items: [
            {
              title: t('help_center'),
              link: `https://everpay.zendesk.com/hc/${locale.includes('zh') ? 'zh-cn' : 'en-us'}`
            },
            {
              title: t('privacy_policy'),
              link: 'https://eokptpbng3aag6gn7bgpe2mjfhtedjslj44xgm7bpxqtghnc43rq.arweave.net/I5T5vC02wAN4zfhM8mmJKeZBpktPOXMz4X3hMx2i5uM'
            },
            {
              title: t('faqs'),
              link: locale.includes('zh')
                ? 'https://everpay.zendesk.com/hc/zh-cn/categories/4403074774809-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98'
                : 'https://everpay.zendesk.com/hc/en-us/categories/4403074774809-FAQs'
            }
          ]
        },
        {
          title: t('about'),
          items: [
            {
              title: t('light_paper'),
              link: locale.includes('zh')
                ? 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/mafuxM9-S4pcHtXhwfLPjLkDCoWQxtQsyEn-mhmiXAQ'
                : 'https://mirror.xyz/0x6451eB7f668de69Fb4C943Db72bCF2A73DeeC6B1/aV1mucZp4JAG7j3PnZiL-RcnDBc1VIxBTp2QREqiCIQ'
            },
            {
              title: 'everVision',
              link: 'https://ever.vision/#/'
            }
          ]
        }
      ]
    }

    const footerEntries = computed(() => {
      return getComputedFooterEntries(t, locale.value)
    })

    const year = new Date().getFullYear()

    return {
      isMobile,
      footerEntries,
      socials,
      year,
      t
    }
  }
})

</script>

<template>
  <footer class="pt-16 pb-7 px-5 lg:pb-10 lg:pt-20 bg-everPurple4 text-everGray4">
    <div class="flex-row items-start justify-between pb-16  ml-auto mr-auto w-full lg:pb-28 lg:flex" style="max-width: 1440px">
      <div class="mb-10">
        <img class="w-28 mb-3.5" :src="require('../images/everscan-white.png')">
        <div class="slogan text-sm text-everGray4">
          {{ t('slogan') }}
        </div>
      </div>
      <ul class="flex-row items-start justify-start sm:flex lg:justify-end lg:ml-6">
        <li
          v-for="(entryItem, index) in footerEntries"
          :key="index"
          class="float-left mb-14 w-1/2 sm:float-none sm:mb-0 sm:w-56 lg:w-60 last:w-auto"
          :class="index === 2 ? 'clear-both' : ''"
        >
          <h4 class="text-white text-sm font-bold mb-4">
            {{ entryItem.title }}
          </h4>
          <ul>
            <li v-for="(linkItem, linkIndex) in entryItem.items" :key="linkIndex" class="mb-4 last:mb-0">
              <router-link v-if="linkItem.to" :to="linkItem.to">
                {{ linkItem.title }}
              </router-link>
              <a
                v-else
                class="text-sm transition-colors hover:text-everGray2"
                :target="!isMobile && linkItem.link ? '_blank' : ''"
                :href="linkItem.link ? linkItem.link : 'javascript:void(0);'"
              >
                {{ linkItem.title }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
    <div class="clear-both flex-row items-center justify-between ml-auto mr-auto w-full sm:flex" style="max-width:1440px">
      <div class="text-sm">
        © {{ year }} everPay. All rights reserved.
      </div>
      <div class="flex flex-row items-center justify-start sm:justify-end">
        <a
          v-for="social in socials"
          :key="social.link"
          :href="social.link"
          :target="!isMobile ? '_blank' : ''"
          class="mr-5 mt-2 sm:mr-0 sm:ml-6 sm:mt-0"
        >
          <img
            class="block w-6 h-6"
            :src="require(`../images/${social.name}.svg`)"
          >
        </a>
      </div>
    </div>
  </footer>
</template>
