
import { defineComponent, ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { formatMoney } from '@/libs/utils'
import { CountTo } from 'vue3-count-to'
export default defineComponent({
  components: {
    CountTo
  },
  props: {
    tradingNum: {
      type: Number,
      default: 0
    },
    contentIndex: {
      type: Number,
      default: -1
    },
    boxIndex: {
      type: Number,
      default: -1
    }
  },
  emits: ['open'],
  setup (props) {
    const startVal = ref(props.tradingNum)
    watch(() => props.tradingNum, () => {
      setTimeout(() => {
        startVal.value = props.tradingNum
      }, 2000)
    })
    const { t, locale } = useI18n()
    const i18Key = [
      ['pending_tx_num',
        'packaged_tx_num'
      ],
      [
        'total_tx_num',
        'total_users'
      ]
    ]
    const imgSrc = [
      [
        'pending',
        'packaged'
      ],
      [
        'total',
        'total_users'
      ]
    ]
    const i18 = computed(() => i18Key[props.boxIndex][props.contentIndex])
    const img = computed(() => imgSrc[props.boxIndex][props.contentIndex])
    return {
      t,
      locale,
      i18Key,
      imgSrc,
      formatMoney,
      startVal,
      i18,
      img
    }
  }
})
