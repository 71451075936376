
import { computed, defineComponent, PropType, ref, watchEffect, nextTick } from 'vue'
import Item from './Item.vue'
import ModalWrapper from '@/components/common/ModalWrapper.vue'
import * as echarts from 'echarts'
import { useI18n } from 'vue-i18n'
import { toBN } from '@/libs/everpay-js/utils/util'
import MoreInfo from '@/components/common/MoreInfo.vue'

interface UsersNum {
  walletNum: number
  eidWalletNum: number
  arWalletNum: number
  evmWalletNum: number
}
export default defineComponent({
  components: {
    Item,
    ModalWrapper,
    MoreInfo
  },
  props: {
    txNum: {
      type: Array as PropType<number[][]>,
      default: () => []
    },
    usersChartsData: {
      type: Object as PropType<UsersNum>,
      default: () => {}
    }
  },
  setup (props) {
    const charts = ref()
    const { t, locale } = useI18n()
    const demographicVisible = ref(false)
    const userData = computed(() => {
      return [
        {
          name: t('total_users'),
          value: props.usersChartsData.walletNum,
          tip: t('total_users_tip')
        },
        {
          name: 'EverID (Email)',
          value: props.usersChartsData.eidWalletNum,
          tip: t('everid_tip')
        },
        {
          name: t('demographic_type'),
          value: 3,
          tip: ''
        }
      ]
    })
    // 计算前六位数字
    const sliceNumber = (str:string):string => {
      let result = ''
      let index = 0
      for (let i = 0; i < str.length; i++) {
        const s = str[i]
        if (index === 6) {
          break
        }
        if (s && s !== ',') {
          index += 1
        }
        result += s
      }
      return result
    }
    // 最大余额法解决百分比和不是100%的问题
    const getPercentValue = (arrList:{name:string, value:number}[], name:string, precision:number) => {
      const index = arrList.findIndex((a:any) => a.name === name)
      if (!arrList[index]) {
        return 0
      }
      if (!precision) precision = 0
      const sum = arrList.reduce((acc, val) => {
        return acc + (isNaN(val.value) ? 0 : val.value)
      }, 0)
      if (sum === 0) {
        return 0
      }
      const digits = Math.pow(10, precision)
      const votesPerQuota = arrList.map((val) => {
        return (isNaN(val.value) ? 0 : val.value) / sum * digits * 100
      })

      const targetSeats = digits * 100
      const seats = votesPerQuota.map((votes) => {
        return Math.floor(votes)
      })

      let currentSum = seats.reduce((acc, val) => {
        return acc + val
      }, 0)

      const remainder = votesPerQuota.map(function (votes, index) {
        return votes - seats[index]
      })

      while (currentSum < targetSeats) {
        let max = Number.NEGATIVE_INFINITY
        let maxId = null
        for (let i = 0, len = remainder.length; i < len; ++i) {
          if (remainder[i] > max) {
            max = remainder[i]
            maxId = i
          }
        }
        if (maxId !== null) {
          ++seats[maxId]
          remainder[maxId] = 0
        }
        ++currentSum
      }

      return seats[index] / digits
    }
    const myChart = ref<echarts.ECharts | null>(null)
    const initEcharts = () => {
      const initData = [
        {
          value: props.usersChartsData.evmWalletNum,
          name: 'ETH',
          itemStyle: {
            color: '#AF81DE'
          }
        },
        {
          value: props.usersChartsData.arWalletNum,
          name: 'AR',
          itemStyle: {
            color: '#C2AEF2'
          }
        },
        {
          value: props.usersChartsData.eidWalletNum,
          name: 'Email',
          itemStyle: {
            color: '#797EC9'
          }
        }
      ]
      myChart.value = echarts.init(charts.value)
      const option:echarts.EChartsCoreOption = {
        tooltip: {
          trigger: 'item',
          show: true,
          position: function (point:number[]) {
            return [point[0] + 10, point[1] + 10]
          },
          borderColor: '#EDF0FC',
          backgroundColor: '#ffffff',
          borderWidth: 0.8,
          textStyle: {
            color: '#797EC9',
            fontSize: '12px',
            lineHeight: '20px',
            fontWeight: 400
          },
          padding: 4,
          extraCssText: 'box-shadow: 0px 2px 16px 0px rgba(70, 70, 70, 0.08);',
          formatter: (value:any) => {
            return `<div style="display:flex;"><div style="background-color:#797EC9;border-radius:100%; width: 6px; height: 6px;margin-right: 4px;"></div><div style=" display: flex;align-items: center;">${value.name} ${(locale.value.includes('zh') ? ' 地址' : ' Addresses')} <span style="color: #5356BD;font-weight: 500;margin-left:4px">${value.value} | ${getPercentValue(initData, value.name, 0)}%</span></div></div>`
          }
        },
        legend: {
          width: 0,
          bottom: 'center',
          right: '0%',
          icon: 'roundRect',
          itemWidth: 16,
          itemHeight: 16,
          selectedMode: false,
          textStyle: {
            fontSize: 12,
            align: 'center',
            lineHeight: 20,
            padding: [0, 0, 0, 8],
            rich: {
              name: {
                color: '#000000A6'
              },
              num: {
                fontWeight: 500,
                color: '#000000D9'
              },
              unit: {
                fontWeight: 500,
                color: '#000000D9'
              }
            }
          },

          // 格式化图例文本
          formatter (name:string) {
            const percentage = getPercentValue(initData, name, 2)

            const v = initData.find((i) => i.name === name)?.value
            const formatName = name + (locale.value.includes('zh') ? ' 地址' : ' Addresses')
            if (window.innerWidth > 500) {
              return [`{name|${formatName}} {num|${v + ' |'}} {unit|${percentage}%}`].join('\n')
            } else {
              return [`{name|${formatName}}`, `{num|${v + ' |'}} {unit|${percentage}%}`].join('\n')
            }
          }
        },
        series: [
          {
            name: 'Users Num',
            type: 'pie',
            radius: ['65%', '100%'],
            center: [window.innerWidth > 500 ? '20.5%' : '25.5%', '50%'], // 设置饼状图位置，第一个百分数调水平位置，第二个百分数调垂直位置
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 20,
                fontWeight: 'normal',
                lineHeight: 28,
                color: '#797EC9'
              },
              scale: false
            },
            labelLine: {
              show: false
            },
            data: initData
          }
        ]
      }

      myChart.value.setOption(option)
      window.addEventListener('resize', () => {
        myChart.value?.setOption({
          series: [
            {
              center: [window.innerWidth > 500 ? '20.5%' : '25.5%', '50%']
            }
          ]
        })
        myChart.value?.resize()
      })
    }
    watchEffect(() => {
      if (demographicVisible.value) {
        nextTick(() => {
          initEcharts()
          myChart.value?.resize()
        })
      }
    }, {
      flush: 'post'
    })

    return {
      charts,
      t,
      userData,
      demographicVisible,
      toBN,
      sliceNumber
    }
  }
})
