<template>
  <div class="flex text-sm flex-row items-center justify-between" style="height:78px">
    <div class="flex flex-col justify-between w-36 whitespace-nowrap">
      <slot />
      <div v-if="isArid" class="sm:mt-0.5">
        {{ rollupTxNum + ' ' + t('tx_num') }}
      </div>
      <div v-else class="sm:mt-0.5">
        {{ capitalize(type) }}
      </div>
    </div>
    <div class="text-everBlack4">
      <div v-if="isArid" class="flex flex-row justify-between">
        <div>
          {{ t('miner') }}
        </div>
        <SimpleWrapper>
          <a :href="minnnerUrl" target="_blank">
            <MoreInfo :fixed-width="true" :info="miner" :left-view="true">{{ owner }}</MoreInfo>
          </a>
        </SimpleWrapper>
      </div>
      <div v-else class="flex justify-end cursor-pointer sm:mt-0.5">
        <MoreInfo v-if="replaceAmountLenght > 6" :info="formatTotalAmount">
          {{ totalAmount + '' + tokenSymbol }}
        </MoreInfo>
        <span v-else>{{ formatTotalAmount }}</span>
      </div>
      <div class="whitespace-nowrap sm:mt-0.5">
        {{ datetime }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import MoreInfo from '@/components/common/MoreInfo.vue'
import dayjs from 'dayjs'
import { fromDecimalToUnit } from '@/libs/everpay-js/utils/util'
import { getExplorerUrl, formatNumber, formatMoney } from '@/libs/utils'
import { ChainType, Token } from '@/libs/everpay-js'
import { useI18n } from 'vue-i18n'
import SimpleWrapper from './SimpleWrapper.vue'
import capitalize from 'lodash/capitalize'
export default defineComponent({
  components: {
    MoreInfo,
    SimpleWrapper
  },
  props: {
    timeNonce: {
      type: Number,
      default: 0
    },
    action: {
      type: String,
      default: ''
    },
    owner: {
      type: String,
      default: ''
    },
    rollupTxNum: {
      type: Number,
      default: 0
    },
    amount: {
      type: String,
      default: ''
    },
    tokenSymbol: {
      type: String,
      default: ''
    },
    token: {
      type: Object as PropType<Token | undefined>,
      default: () => ({})
    },
    isArid: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { t } = useI18n()
    const datetime = computed(() => {
      return dayjs(+props.timeNonce).format('YYYY-MM-DD HH:mm:ss')
    })
    const type = computed(() => {
      if (props.action === 'mint') return 'deposit'
      if (props.action === 'burn') return 'withdraw'
      return props.action
    })
    const miner = computed(() => {
      return `${props.owner.slice(0, 4)}...${props.owner.slice(-4)}`
    })
    const minnnerUrl = getExplorerUrl({
      type: 'address',
      value: props.owner
    }, ChainType.arweave)
    const totalAmount = computed(() => {
      return fromDecimalToUnit(props.amount, props.token?.decimals || 18)
    })
    const replaceAmountLenght = computed(() => {
      return totalAmount.value.replaceAll(',', '').replace('.', '').length
    })
    const formatTotalAmount = computed(() => {
      if (replaceAmountLenght.value > 6) {
        return formatNumber(totalAmount.value, 6) + '...' + props.tokenSymbol.toUpperCase()
      }
      return formatMoney(totalAmount.value) + ' ' + props.tokenSymbol.toUpperCase()
    })
    return {
      t,
      datetime,
      formatTotalAmount,
      miner,
      type,
      minnnerUrl,
      totalAmount,
      capitalize,
      replaceAmountLenght
    }
  }
})
</script>

<style>
</style>
