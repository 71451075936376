import {
  ElIcon,
  ElMessage
} from 'element-plus'
import {
  CircleCheckFilled,
  CircleCloseFilled,
  ArrowLeftBold,
  ArrowRightBold
} from '@element-plus/icons-vue'
const components = [
  ElIcon,
  ElMessage,
  CircleCheckFilled,
  CircleCloseFilled,
  ArrowLeftBold,
  ArrowRightBold
]
const plugins = [
  ElMessage
]

export const importElementPlus = (app: any): void => {
  components.forEach(component => {
    app.component(component.name, component)
  })

  plugins.forEach(plugin => {
    app.use(plugin)
  })
}
