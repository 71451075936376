import { ChainType, Token } from '@/libs/everpay-js'

export interface State {
  account: string
  accChainType: ChainType
  manualBurnTokens: ManualBurnTokens[]
  currency: string
  tokenList: Token[]
  lockers: AllLocker
  currencyPrices: CurrencyPriceItem[]
}

export interface CurrencyPriceItem {
  symbol: string
  price: string
}
export interface LockersInfo {
  owner: string
  locker: AllLocker
  trackerLastArId: string
}

export interface Locker {
  chainType?: string
  chainId?: string
  address?: string
  totalAmount?: number
}

export interface AllLocker {
  arweave: Locker
  ethereum: Locker
  moon: Locker
  conflux: Locker
  bsc: Locker
  platon: Locker
  mapo: Locker
  aostest: Locker
  psntest: Locker
}

export interface ManualBurnTokens {
  tokenTag: string
  targetChainType: string
}

export const defaultState: State = {
  account: '',
  accChainType: ChainType.ethereum,
  manualBurnTokens: [],
  currency: 'USD',
  tokenList: [],
  lockers: {
    arweave: {},
    ethereum: {},
    moon: {},
    conflux: {},
    bsc: {},
    platon: {},
    mapo: {},
    aostest: {},
    psntest: {}
  },
  currencyPrices: []
}
