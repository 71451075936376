<script lang="ts">
import ModalMask from '@/components/common/ModalMask.vue'
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  components: {
    ModalMask
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 0
    }
  },
  emits: ['close'],
  setup () {

  }
})
</script>

<template>
  <transition name="fade">
    <div v-show="visible" class="fixed z-40 h-full w-full top-0 left-0">
      <modal-mask @click="$emit('close')" />
      <div
        class="w-full flex flex-col max-w-416px smallH5ModalMaxWidth fixed p-6 bg-white rounded-xl z-30 top-1/2 transform -translate-y-1/2 left-1/2 -translate-x-1/2"
      >
        <div class="flex flex-col justify-between border-b border-solid pb-4 mb-4 text-black text-opacity-65 border-black border-opacity-6">
          <div class="flex items-center w-full justify-between flex-row">
            <div class="text-base font-medium">
              {{ title }}
            </div>
            <i class="el-icon-close cursor-pointer text-2xl opacity-45 text-black" @click="$emit('close')" />
          </div>
        </div>
        <div class="flex-1 relative flex flex-col justify-between">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>
