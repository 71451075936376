<template>
  <div class="">
    <div
      class="flex-row relative w-full max-w-xl  items-center rounded-lg sm:py-0.5 py-px pl-px sm:pl-0.5 flex h-9 bg-homegradient border-box"
    >
      <input
        v-model.trim="inputValue"
        class="px-2 border-box sm:text-sm text-xs w-full h-full rounded-l-md"
        :placeholder="t('search_placeholder')"
        @keydown.enter="doSearch"
      >
      <button
        class="h-full w-9 flex flex-row items-center justify-center"
        @click="doSearch"
      >
        <img v-if="loading" class="w-5 h-5 animate-spin-slow" :src="require('../images/loading-white.svg')">
        <img v-else class="w-5 h-5" :src="require('../images/search.svg')">
      </button>
      <div
        v-if="to"
        class="z-50 truncate cursor-pointer absolute w-full top-10 left-0 h-12 text-sm px-3 py-1 bg-white rounded-lg flex flex-row items-center"
        style="box-shadow: 4px 4px 20px #EFEAEE;"
        @click="handleTo"
      >
        {{ inputValue }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { searchForScan, getAnsResolver } from '@/libs/api'
import { getEverpay } from '@/libs/everpay'
import { defineComponent, ref, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ElMessage } from 'element-plus'
import { useI18n } from 'vue-i18n'
import { isSmartAccount } from '@/libs/everpay-js/utils/util'
import { genEverId } from '@/libs/utils'
import debounce from 'lodash/debounce'

const needToSearch = (value:string) => {
  let accountChainType = ''
  try {
    accountChainType = getEverpay().getAccountChainType(value)
  } catch (e) {}

  if (accountChainType) {
    return true
  }

  if (value.startsWith('0x') && value.length === 66) {
    return true
  }
  if (isSmartAccount(value)) {
    return true
  }
  // eid
  if (value.search(/^eid[0-9a-fA-F]{68}$/) !== -1) {
    return true
  }
  return false
}

export default defineComponent({
  setup () {
    const { t } = useI18n()
    const inputValue = ref('')
    const loading = ref(false)
    const router = useRouter()
    const route = useRoute()
    const to = ref('')
    const handleTo = () => {
      router.push(to.value)
      to.value = ''
      inputValue.value = ''
    }
    watch(() => route.name, () => {
      inputValue.value = ''
    })
    const debounceGetAns = debounce(async () => {
      try {
        const ansResolver = await getAnsResolver(inputValue.value)
        if (ansResolver.address) {
          to.value = `/account/${ansResolver.address}`
        } else {
          to.value = ''
          ElMessage({
            showClose: true,
            message: `<div class="">
              <div>${t('input_format')}</div>
              <div>${t('if_you_help')} <a class="underline underline-offset-1" href="https://discord.com/invite/WM5MQZGVPF" target="_blank">${t('please_contact_us')}</a></div>
            </div>`,
            duration: 3000,
            type: 'error',
            dangerouslyUseHTMLString: true
          })
        }
      } catch (e:any) {
      } finally {
        loading.value = false
      }
    }, 800)

    const setToValue = async () => {
      if (inputValue.value.search(/\.ar\s*$/) !== -1) {
        loading.value = true
        debounceGetAns()
      } else if (needToSearch(inputValue.value)) {
        const result = await searchForScan(inputValue.value)
        if (isSmartAccount(inputValue.value)) {
          const everId = await genEverId(inputValue.value)
          to.value = `/account/${everId}`
        } else if (result.type === 'arTx') {
          to.value = `/arId/${result?.arTx?.arId}`
        } else if (result.type === 'account') {
          to.value = `/account/${inputValue.value}`
        } else if (result.type === 'tx') {
          to.value = `/tx/${result?.tx?.everHash}`
        }
        loading.value = false
      }
    }
    const doSearch = () => {
      if (loading.value) {
        return
      }
      if (to.value) {
        handleTo()
      } else {
        ElMessage({
          showClose: true,
          message: `<div class="">
              <div>${t('input_format')}</div>
              <div>${t('if_you_help')} <a class="underline underline-offset-1" href="https://discord.com/invite/WM5MQZGVPF" target="_blank">${t('please_contact_us')}</a></div>
            </div>`,
          duration: 3000,
          type: 'error',
          dangerouslyUseHTMLString: true
        })
      }
    }
    watch(inputValue, () => {
      if (inputValue.value) {
        setToValue()
      }
    })
    return {
      t,
      handleTo,
      inputValue,
      to,
      doSearch,
      loading
    }
  }
})
</script>

<style lang="scss" scoped>
input {

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }

  &::placeholder {
    color: rgba(0, 0, 0, 0.25);
  }

  -moz-appearance: textfield;
  outline: none;
}
</style>
