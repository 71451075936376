
import { computed, defineComponent, PropType } from 'vue'
import Card from './Card.vue'
import { useI18n } from 'vue-i18n'
import SimpleWrapper from './SimpleWrapper.vue'
import MoreInfo from '@/components/common/MoreInfo.vue'
import { useStore } from '@/store'
import { formatTokenTag } from '@/libs/utils'
import { RouterLink } from 'vue-router'
export default defineComponent({
  components: {
    Card,
    SimpleWrapper,
    MoreInfo,
    RouterLink
  },
  props: {
    list: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    to: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    const { t } = useI18n()
    const store = useStore()
    const tokens = computed(() => store.state.tokenList)
    const formatInfo = (info: string) => {
      return info.slice(0, 16) + '...'
    }
    return { t, formatInfo, tokens, formatTokenTag }
  }
})
