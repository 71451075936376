import { createRouter, createWebHistory } from 'vue-router'
import Home from '../pages/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/arIds',
    name: 'arIds',
    component: async () => await import('../pages/Arids/index.vue')
  },
  {
    path: '/arId/:arId',
    name: 'arId',
    component: async () => await import('../pages/ArId.vue')
  },
  {
    path: '/tokens',
    name: 'tokens',
    component: async () => await import('../pages/Tokens.vue')
  },
  {
    path: '/token/:tokenTag',
    name: 'token',
    component: async () => await import('../pages/Token.vue')
  },
  {
    path: '/txs',
    name: 'txs',
    component: async () => await import('../pages/Txs.vue')
  },
  {
    path: '/tx/:everHash',
    name: 'tx',
    component: async () => await import('../pages/Tx.vue')
  },
  {
    path: '/account/:accountId',
    name: 'account',
    component: async () => await import('../pages/Account.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})
router.afterEach(() => {
  document.documentElement.scrollTop = 0
})

export default router
