<template>
  <div class="hover:text-scanHoverColor cursor-pointer text-scanMainColor whitespace-normal">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
})
</script>

<style>

</style>
