
import ModalMask from '@/components/common/ModalMask.vue'
import { defineComponent } from '@vue/runtime-core'

export default defineComponent({
  components: {
    ModalMask
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    height: {
      type: Number,
      default: 0
    }
  },
  emits: ['close'],
  setup () {

  }
})
