<template>
  <div class="flex flex-col items-center" :class="i18 === 'total_users' ? 'cursor-pointer' : ''" @click="i18 === 'total_users' ? $emit('open') : ''">
    <img :src="require(`@/images/lockout/${img}.png`)" class="w-11 h-11 sm:w-20 sm:h-20">
    <div class="mt-2 sm:mt-6 flex flex-col items-center">
      <div
        :class="locale === 'zh' || locale === 'zh-TW' ? 'whitespace-nowrap' : ''"
        class="text-scanMainColor w-85px sm:w-full text-xs text-center flex justify-center  sm:text-sm items-center">
        {{ t(i18) }}
        <div v-if="i18 === 'total_users'" class="flex items-center justify-center px-1 text-12px rounded bg-everBlack3 text-black text-opacity-65 ml-1 cursor-pointer min-w-max">
          <img src="@/images/charts.svg" alt="charts"> <div class="xs:block hidden">
            {{ t('demographic') }}
          </div>
        </div>
      </div>
      <CountTo
        class="flex text-sm  sm:text-32px text-scanClick mt-1 items-center justify-center"
        :start-val="startVal"
        :end-val="tradingNum"
        :duration="3000" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { formatMoney } from '@/libs/utils'
import { CountTo } from 'vue3-count-to'
export default defineComponent({
  components: {
    CountTo
  },
  props: {
    tradingNum: {
      type: Number,
      default: 0
    },
    contentIndex: {
      type: Number,
      default: -1
    },
    boxIndex: {
      type: Number,
      default: -1
    }
  },
  emits: ['open'],
  setup (props) {
    const startVal = ref(props.tradingNum)
    watch(() => props.tradingNum, () => {
      setTimeout(() => {
        startVal.value = props.tradingNum
      }, 2000)
    })
    const { t, locale } = useI18n()
    const i18Key = [
      ['pending_tx_num',
        'packaged_tx_num'
      ],
      [
        'total_tx_num',
        'total_users'
      ]
    ]
    const imgSrc = [
      [
        'pending',
        'packaged'
      ],
      [
        'total',
        'total_users'
      ]
    ]
    const i18 = computed(() => i18Key[props.boxIndex][props.contentIndex])
    const img = computed(() => imgSrc[props.boxIndex][props.contentIndex])
    return {
      t,
      locale,
      i18Key,
      imgSrc,
      formatMoney,
      startVal,
      i18,
      img
    }
  }
})
</script>

<style></style>
