
import { ref, computed, onMounted, watch, defineComponent } from 'vue'
import ScrollPicker from 'vue3-scroll-picker'
import { useRoute } from 'vue-router'
import { useI18n } from 'vue-i18n'
import Search from './Search.vue'
import { isProd, everpayOfficialWebsiteHost, savedI18nStorageKey, isMobile, isEverpayApp, isEverpayFarm, isEverpayOfficialWebsite, everpayAppHost, everpayScanHost, isEverpayScan } from '@/constants'
import { checkParentsHas } from '@/libs/utils'

export default defineComponent({
  components: {
    ScrollPicker,
    Search
  },
  props: {
    scrollTop: {
      type: Number,
      default: 0
    }
  },
  emits: ['handleLocaleChange', 'handleBtnClick'],
  setup (props, context) {
    const isLangWrapperTarget = checkParentsHas('lang-wrapper')
    const isResourcesWrapperTarget = checkParentsHas('resources-wrapper')
    const { t, locale } = useI18n({ useScope: 'global' })
    const route = useRoute()
    const windowWidth = ref(document.documentElement.offsetWidth)
    const mobileNavModalOpened = ref(false)
    const languagesVisible = ref(false)
    const resourcesVisible = ref(false)
    const navbarTextColorClass = isEverpayFarm ? 'text-everPurple9' : 'text-everPurple0'
    const navbarTextColorHoverClass = isEverpayFarm ? 'hover:text-everPurple9' : 'hover:text-everPurple0'
    const navbarBtnBorderClass = isEverpayFarm ? 'border-everPurple9' : 'border-everPurple0'
    const navbarBtnBackClass = isEverpayFarm ? 'connect-btn-bg-farm' : 'connect-btn-bg-normal'
    const navItems = computed(() => {
      return [
        {
          title: 'home',
          routeNames: ['home'],
          openNew: false,
          to: `${everpayScanHost}/`
        },
        {
          title: 'transactions',
          routeNames: ['txs'],
          openNew: false,
          to: `${everpayScanHost}/txs`
        },
        {
          title: 'tokens',
          routeNames: ['tokens'],
          openNew: false,
          to: `${everpayScanHost}/tokens`
        },
        {
          title: 'apps',
          routeNames: [],
          to: ''
        }
      ]
    })

    const appItems = computed(() => {
      return [
        {
          title: 'discover_dapps',
          openNew: !isEverpayApp,
          to: `https://beta${isProd ? '' : '-dev'}.everpay.io/dapps`
        },
        {
          title: 'deposit',
          openNew: !isEverpayApp,
          to: `${everpayAppHost}/deposit`
        },
        {
          title: 'wallet',
          openNew: !isEverpayApp,
          to: everpayAppHost
        },
        {
          title: 'documentation',
          openNew: !isEverpayFarm,
          to: `https://docs.everpay.io/${
            (locale.value as any).includes('zh') ? '' : 'en/'
          }`
        }
      ]
    })
    const displaySearch = computed(() => route.name !== 'home')
    const mobileNavItems = computed(() => {
      return navItems.value.filter(item => item.title !== 'apps').concat(appItems.value as any)
    })

    const languages = [
      {
        value: 'en',
        label: 'English'
      },
      {
        value: 'zh',
        label: '简体中文'
      }
    ]
    if (isEverpayScan) {
      languages.push({
        value: 'fr',
        label: 'Français'
      },
      {
        value: 'zh-TW',
        label: '繁體中文'
      })
    }
    if (isEverpayOfficialWebsite) {
      languages.push({
        value: 'es',
        label: 'Español'
      },
      {
        value: 'fr',
        label: 'Français'
      },
      {
        value: 'zh-TW',
        label: '繁體中文'
      })
    }
    const scrollPickerOptions = [languages]
    const scrollPickerValues = ref([locale.value])

    watch(locale, () => {
      scrollPickerValues.value = [locale.value]
    })

    onMounted(() => {
      window.addEventListener('resize', () => {
        windowWidth.value = document.documentElement.offsetWidth
        if (windowWidth.value >= 640) {
          mobileNavModalOpened.value = false
          languagesVisible.value = false
        }
        resourcesVisible.value = false
      })

      window.addEventListener('scroll', () => {
        if (windowWidth.value >= 640) {
          languagesVisible.value = false
        }
        resourcesVisible.value = false
      })

      document.addEventListener('click', (e) => {
        if (!isLangWrapperTarget(e.target as any)) {
          languagesVisible.value = false
        }
        if (!isResourcesWrapperTarget(e.target as any)) {
          resourcesVisible.value = false
        }
      })
    })

    const changeLocale = (lang: string) => {
      window.localStorage.setItem(savedI18nStorageKey, lang)
      locale.value = lang
      context.emit('handleLocaleChange', lang)
      mobileNavModalOpened.value = false
      languagesVisible.value = false
    }

    const confirmLocale = () => {
      locale.value = scrollPickerValues.value[0]
      context.emit('handleLocaleChange', locale.value)
      languagesVisible.value = false
      mobileNavModalOpened.value = false
    }
    return {
      t,
      isProd,
      isEverpayApp,
      isMobile,
      everpayOfficialWebsiteHost,
      locale,
      route,
      navItems,
      languagesVisible,
      languages,
      changeLocale,
      confirmLocale,
      mobileNavModalOpened,
      scrollPickerValues,
      scrollPickerOptions,
      resourcesVisible,
      appItems,
      mobileNavItems,
      navbarBtnBorderClass,
      navbarTextColorClass,
      navbarTextColorHoverClass,
      navbarBtnBackClass,
      displaySearch
    }
  }
})

