import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { defaultState, Locker, State } from './state'
import { getLockerInfo, getMarketPrices, getmanualBurnTokens } from '@/libs/api'
import { getEverpay } from '@/libs/everpay'

// define injection key
// eslint-disable-next-line symbol-description
export const vuexStoreKey: InjectionKey<Store<State>> = Symbol()

export default createStore<State>({
  state: defaultState,

  mutations: {
    updateAccount (state, account) {
      state.account = account
    },
    updateAccChainType (state, accChainType) {
      state.accChainType = accChainType
    },
    updateTokenList (state, tokenList) {
      state.tokenList = tokenList
    },
    updateLocker (state, lockers) {
      state.lockers = lockers
    },
    updateCurrencyPrices (state, currencyPrices) {
      state.currencyPrices = currencyPrices
    },
    updateManualBurnTokens (state, tokens) {
      state.manualBurnTokens = tokens
    }
  },

  actions: {
    async updateTokenListAsync ({ commit }, page) {
      const tokenInfo = await getEverpay().info()
      commit('updateTokenList', tokenInfo.tokenList)
    },
    async updateLockerInfoAsync ({ state, commit }) {
      const lockerInfo = await getLockerInfo()
      const lockers: any = {}
      Object.keys(state.lockers).forEach((item) => {
        const locker = Object.values(lockerInfo.locker).find(
          (locker: Locker) => {
            return item === locker.chainType
          }
        )
        if (locker !== undefined) {
          lockers[item] = locker
        }
      })
      commit('updateLocker', lockers)
    },
    async updateCurrencyPricesAsnyc ({ state, commit }) {
      const { currency } = state
      const prices = await getMarketPrices(currency, state.tokenList)
      commit('updateCurrencyPrices', prices)
    },
    async updateManualBurnTokensAsync ({ commit }) {
      const tokens = await getmanualBurnTokens()
      commit('updateManualBurnTokens', tokens)
    }
  },

  modules: {},

  plugins: [createPersistedState()]
})

// define your own `useStore` composition function
export const useStore = function (): Store<State> {
  return baseUseStore(vuexStoreKey)
}
