<template>
  <img :src="require(`@/images/chainType/${logo}`)" alt="chainTypeLogo">
</template>

<script lang='ts'>
import { computed, defineComponent } from 'vue'
import { isProd } from '@/constants'
export default defineComponent({
  props: {
    targetChainType: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const chainTypeLogoListSvg = [
      'ethereum',
      'arweave',
      'moon',
      'platon',
      'bsc',
      'conflux',
      'aostest',
      'psntest'
    ]
    const chainTypeLogoListPng = [
      'mapo'
    ]
    const logo = computed(() => {
      if (props.targetChainType && props.targetChainType.toLowerCase() === 'psntest') {
        return isProd ? 'psntwork.svg' : 'psntest.svg'
      }
      if (props.targetChainType && chainTypeLogoListSvg.some((t) => t.toLowerCase() === props.targetChainType.toLowerCase())) {
        return `${props.targetChainType}.svg`
      } else if (props.targetChainType && chainTypeLogoListPng.some((t) => t.toLowerCase() === props.targetChainType.toLowerCase())) {
        return `${props.targetChainType}.png`
      }
      return 'default.svg'
    })
    return {
      logo
    }
  }
})
</script>

<style>

</style>
