
import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { getExplorerUrl, formatMoney } from '@/libs/utils'
import capitalize from 'lodash/capitalize'
import { isProd } from '@/constants'
import ChainTypeLogo from '@/components/ChainTypeLogo.vue'
import { Locker } from '@/store/state'
import { ChainType } from '@/libs/everpay-js'
export default defineComponent({
  components: {
    ChainTypeLogo
  },
  props: {
    lockout: {
      type: Object as PropType<Locker>,
      default: () => {}
    },
    width: {
      type: String,
      default: ''
    },
    lastLockerClass: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { t } = useI18n()
    const hrefUrl = computed(() => {
      return getExplorerUrl({
        type: 'address',
        value: props.lockout.address as string
      }, props.lockout.chainType as ChainType)
    })
    const formatAddress = computed(() => {
      if (props.lockout.address) {
        return (props.lockout.address).slice(0, 10) + '...' + (props.lockout.address).slice(-10)
      }
      return ''
    })
    const totalAmount = computed(() => {
      if (props.lockout.totalAmount) {
        return formatMoney((+props?.lockout?.totalAmount).toFixed(2)) + ' USD'
      }
      return '0' + ' USD'
    })
    return {
      t,
      hrefUrl,
      formatMoney,
      formatAddress,
      capitalize,
      isProd,
      totalAmount
    }
  }
})
