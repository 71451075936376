<template>
  <div
    :style="`width:${width}`"
    :class="lastLockerClass"
    class="flex text-white px-10 sm:px-0 flex-col justify-between text-sm">
    <div class="flex items-start sm:items-center  sm:w-auto flex-row">
      <div class="mr-8 sm:mr-11 w-12 h-12 flex items-center justify-center bg-lockerSvg" style="border-radius: 10px">
        <img src="@/images/lockout/lockeraddress.svg" class="w-8 h-8" alt="">
      </div>
      <div class="flex flex-1 h-full flex-col justify-around">
        <div class="flex  items-center cursor-default">
          <ChainTypeLogo :target-chain-type="lockout.chainType" class="w-3.5 h-3.5 sm:w-4 sm:h-4 mr-1" />
          <span class="opacity-80">{{ capitalize(lockout.chainType === 'moon' ? (isProd ? 'moonbeam' : 'moonbase') : lockout.chainType) }} {{ t('locker_address') }}</span>
        </div>
        <div class="break-all cursor-pointer hover:opacity-80">
          <a :href="hrefUrl" class="sm:block hidden break-all" target="_blank">{{ lockout.address }}</a>
          <a :href="hrefUrl" class="sm:hidden block" target="_blank">{{ formatAddress }}</a>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-start sm:items-center mt-6 sm:mt-10">
      <div class="mr-8 sm:mr-11 w-12 h-12 flex items-center justify-center bg-lockerSvg" style="border-radius: 10px">
        <img src="@/images/lockout/totalamount.svg" class="w-8 h-8" alt="">
      </div>
      <div class="flex h-full flex-col flex-1 justify-around">
        <div class="flex items-center">
          <span class="opacity-80 whitespace-nowrap">{{ t('total_lock_amount') }}</span>
        </div>
        <div class="break-all">
          {{ totalAmount }}
        </div>
      </div>
    </div>
  </div>
  <slot />
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import { useI18n } from 'vue-i18n'
import { getExplorerUrl, formatMoney } from '@/libs/utils'
import capitalize from 'lodash/capitalize'
import { isProd } from '@/constants'
import ChainTypeLogo from '@/components/ChainTypeLogo.vue'
import { Locker } from '@/store/state'
import { ChainType } from '@/libs/everpay-js'
export default defineComponent({
  components: {
    ChainTypeLogo
  },
  props: {
    lockout: {
      type: Object as PropType<Locker>,
      default: () => {}
    },
    width: {
      type: String,
      default: ''
    },
    lastLockerClass: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { t } = useI18n()
    const hrefUrl = computed(() => {
      return getExplorerUrl({
        type: 'address',
        value: props.lockout.address as string
      }, props.lockout.chainType as ChainType)
    })
    const formatAddress = computed(() => {
      if (props.lockout.address) {
        return (props.lockout.address).slice(0, 10) + '...' + (props.lockout.address).slice(-10)
      }
      return ''
    })
    const totalAmount = computed(() => {
      if (props.lockout.totalAmount) {
        return formatMoney((+props?.lockout?.totalAmount).toFixed(2)) + ' USD'
      }
      return '0' + ' USD'
    })
    return {
      t,
      hrefUrl,
      formatMoney,
      formatAddress,
      capitalize,
      isProd,
      totalAmount
    }
  }
})
</script>

<style>
</style>
