<template>
  <div
    class="fixed top-0 left-0 w-full h-full bg-black z-20 bg-opacity-50"
    @click="$emit('click')" />
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  emits: ['click']
})
</script>
