
import { Locker } from '@/store/state'
import { defineComponent, PropType } from 'vue'
import LockoutInfo from './LockoutInfo.vue'
export default defineComponent({
  components: { LockoutInfo },
  props: {
    lockerArr: {
      type: Array as PropType<Locker[][]>,
      default: () => []
    },
    isScreenLayout: {
      type: Boolean,
      default: true
    },
    lockerWidth: {
      type: String,
      default: ''
    }
  },
  setup () {
    return {}
  }
})
