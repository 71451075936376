<template>
  <div>
    <HomeHeader />
    <div class="max-w-1200px xl:w-1200px xl:mx-auto sm:mt-20 mt-8 mx-5">
      <Lockout />
      <div class="flex flex-row justify-between mt-8 sm:mt-20">
        <NumList :tx-num="txNum" :users-charts-data="usersChartsData" />
      </div>
      <div class="flex mt-8 sm:mt-20  flex-col justify-between md:flex-row">
        <HomeCard
          :list="txsList"
          to="/txs"
          :is-loading="isTxsLoading"
          class="mr-8">
          <div class="flex items-center">
            <img src="@/images/txslist.svg" class="w-6 mr-1"><span class="text-everBlack4 text-base">{{ t('latest_txs') }}</span>
          </div>
        </HomeCard>
        <HomeCard
          :list="aridsList"
          :is-loading="isAridsLoading"
          to="/arIds">
          <div class="flex items-center">
            <img src="@/images/aridslist.svg" class="w-6 mr-1" alt="">
            <MoreInfo>
              <template #info>
                <span class="text-everBlack4 text-base">{{ t('latest_arids') }}</span>
              </template>
              {{ t('covering_rollup_tx_everpay') }}
            </MoreInfo>
          </div>
        </HomeCard>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref, onActivated, onDeactivated } from 'vue'
import { useStore } from '@/store'
import { getAllTxs, getArIdTxs, getTxNum, getUsersNum } from '@/libs/api'
import Lockout from '@/pages/Home/Locker/Locker.vue'
import HomeCard from '@/pages/Home/HomeCard/index.vue'
import NumList from '@/pages/Home/NumList/index.vue'
import HomeHeader from '@/pages/Home/Header.vue'
import { useI18n } from 'vue-i18n'
import { Txs, ArIdTxs } from '@/pages/interface'
import { handleErrorMsg } from '@/libs/utils'
import MoreInfo from '@/components/common/MoreInfo.vue'
export default defineComponent({
  components: {
    Lockout,
    NumList,
    HomeCard,
    HomeHeader,
    MoreInfo
  },
  setup () {
    const store = useStore()
    const { t } = useI18n()
    const txsList:Ref<Txs[]> = ref([])
    const totalTxsList:Ref<Txs[]> = ref([])
    const aridsList:Ref<ArIdTxs[]> = ref([])
    const totalAridsList:Ref<ArIdTxs[]> = ref([])
    const isTxsLoading = ref(true)
    const isAridsLoading = ref(true)
    const firstEverHash = ref('')
    const firstArid = ref('')
    const isOneMount = ref(true)
    const usersChartsData:any = ref({})
    const txNum = ref<number[][]>([])
    const getTxNumReeult = async () => {
      const [result, usersData] = await Promise.all([getTxNum(), getUsersNum()])
      usersChartsData.value = usersData
      const { packagedTxNum, pendingTxNum, totalTxNum } = result
      txNum.value = [[pendingTxNum, packagedTxNum], [totalTxNum, usersData.walletNum]]
    }
    const isTxsDataReturn = ref(false)
    const getAllRequestData = async () => {
      try {
        isTxsDataReturn.value = true
        const [allTxsResult, arIdsResult] = await Promise.all([getAllTxs(0, 10), getArIdTxs(0, 10)])
        if (firstEverHash.value !== allTxsResult.txs[0]?.everHash && allTxsResult?.txs?.length > 0 && allTxsResult) {
          isTxsLoading.value = true
          setTimeout(() => {
            totalTxsList.value = allTxsResult.txs
            getResize()
            firstEverHash.value = allTxsResult.txs[0].everHash
          }, isOneMount.value ? 0 : 2000)
        }
        if (firstArid.value !== arIdsResult.arTxs[0]?.arId && arIdsResult?.arTxs?.length > 0 && aridsList) {
          isAridsLoading.value = true
          setTimeout(() => {
            totalAridsList.value = arIdsResult.arTxs
            getResize()
            firstArid.value = arIdsResult.arTxs[0].arId
          }, isOneMount.value ? 0 : 2000)
        }
        if (isOneMount.value) {
          isTxsLoading.value = false
          isAridsLoading.value = false
        } else {
          setTimeout(() => {
            isTxsLoading.value = false
            isAridsLoading.value = false
          }, 2000)
        }
      } catch (e:any) {
        handleErrorMsg(e, t)
        isTxsLoading.value = false
        isAridsLoading.value = false
      } finally {
        isTxsDataReturn.value = false
        isOneMount.value = false
      }
    }

    const getResize = () => {
      if (window.innerWidth < 640) {
        txsList.value = totalTxsList.value.slice(0, 5)
        aridsList.value = totalAridsList.value.slice(0, 5)
      } else {
        txsList.value = totalTxsList.value
        aridsList.value = totalAridsList.value
      }
    }
    let intervalUpdatetimer = null as any
    onActivated(() => {
      getTxNumReeult()
      store.dispatch('updateTokenListAsync')
      getAllRequestData()
      intervalUpdateFetch()
      window.addEventListener('resize', getResize)
    })
    const intervalUpdateFetch = () => {
      intervalUpdatetimer && clearTimeout(intervalUpdatetimer)
      intervalUpdatetimer = setTimeout(async () => {
        if (!isTxsDataReturn.value) {
          getAllRequestData()
        }
        getTxNumReeult()
        intervalUpdateFetch()
      }, 10000)
    }
    onDeactivated(() => {
      clearTimeout(intervalUpdatetimer)
      window.removeEventListener('resize', getResize)
    })
    return {
      t,
      txsList,
      aridsList,
      isTxsLoading,
      txNum,
      isAridsLoading,
      usersChartsData
    }
  }
})
</script>
<style></style>
