<template>
  <div class="relative w-full">
    <div
      class="absolute w-full overflow-hidden"
      style="z-index: -10;border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;">
      <div class="flex justify-between w-full overflow-hidden h-325px sm:h-435px">
        <div class="bg-homeHeader w-full" />
        <img src="@/images/homeheader.png" class="sm:block hidden opacity-40 h-full" style="min-width: 857px;">
        <img src="@/images/smallheader.png" style="min-width: 410px" class="sm:hidden block">
      </div>
    </div>
    <div class="sm:pt-16 pt-16">
      <div class="sm:pt-20 pt-6 max-w-1200px xl:w-1200px xl:mx-auto mx-5">
        <div class="font-medium sm:w-full w-56 sm:text-40px text-2xl">
          The everPay Blockchain Explorer
        </div>
        <Search class="sm:mt-8 mt-4 sm:w-500px w-full pr-10" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Search from '@/components/Search.vue'
export default defineComponent({
  components: {
    Search
  }
})
</script>
